import { useEffect } from 'react';
import { navigate } from 'gatsby';

const Resources = () => {
  useEffect(() => {
    navigate(`/resources/all-resources/page-1`);
  }, []);
  return '';
};

export default Resources;
